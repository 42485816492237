import {WD, INotification, Logger, getBuildType, CustomerActions, ProcessType, deepClone, getPlatform} from '..';
import {jsonClone} from '../Helper/Helper';
export type NotificationTopic = 'promotion' | 'internal' | 'alerts';
export type NotificationTokenType = 'web' | 'ios' | 'android';
export type WebOrApp = 'web' | 'app';
export class NotificationActions {
  static async refresh() {
    const myNotifications = await WD.store.service.getMyNotifications();
    WD.store.myNotifications = myNotifications;
  }
  static getWebOrApp(): WebOrApp {
    return getPlatform() === 'web' ? 'web' : 'app';
  }
  static getDeviceNotificationType() {
    const platform = getPlatform();
    switch (platform) {
      case 'android': {
        return 'android';
      }
      case 'web': {
        return 'web';
      }
      case 'ios': {
        return 'ios';
      }
      default: {
        return 'web';
      }
    }
  }
  static async refreshWithToken() {
    const type = this.getDeviceNotificationType();
    const webOrApp = this.getWebOrApp();
    if (NotificationActions.userAllowPromotional(webOrApp)) {
      await NotificationActions.subscribeToTopic(type, 'promotion');
    } else {
      await NotificationActions.unsubscribeToTopic(type, 'promotion');
    }
    if (NotificationActions.userAllowAlert(webOrApp)) {
      await NotificationActions.subscribeToTopic(type, 'alerts');
    } else {
      await NotificationActions.unsubscribeToTopic(type, 'alerts');
    }
    if (WD.isUserInternal()) {
      await NotificationActions.subscribeToTopic(type, 'internal');
    } else {
      await NotificationActions.unsubscribeToTopic(type, 'internal');
    }
    if (!CustomerActions.getCustomAttribute('first_topic_subscription_date')) {
      await CustomerActions.setCustomAttribute('first_topic_subscription_date', new Date().toISOString());
    }
  }
  static async markAsRead(notification: INotification) {
    if (!notification.read) {
      WD.store.threadHandler.runSequential(
        ProcessType.NOTIFICATION,
        async () => {
          const undo = deepClone(WD.store.myNotifications);
          try {
            notification = deepClone(notification);
            const newNotification = deepClone(WD.store.myNotifications).filter(
              (n) => n.notificationId !== notification.notificationId,
            );
            newNotification.push(notification);
            await WD.store.service.markNotificationAsRead(notification.notificationId);
          } catch (e) {
            Logger.logError('NOTIFICATION ACTION', 'FAIL TO SET AS READ', e);
            WD.store.myNotifications = undo;
          }
        },
        async () => {
          await this.refresh();
        },
      );
    }
  }
  static async markAllAsRead() {
    if (this.getUnreads().length > 0) {
      WD.store.threadHandler.runSequential(
        ProcessType.NOTIFICATION,
        async () => {
          const undo = jsonClone(WD.store.myNotifications);
          try {
            const newNotifications = jsonClone(WD.store.myNotifications).map((n) => ({...n, read: true}));
            WD.store.myNotifications = newNotifications;
            await WD.store.service.markAllNotificationAsRead();
          } catch (e) {
            Logger.logError('NOTIFICATION ACTION', 'FAIL TO SET AS READ', e);
            WD.store.myNotifications = undo;
          }
        },
        async () => {
          await this.refresh();
        },
      );
    }
  }
  static async markAsDelete(id: string) {
    WD.store.threadHandler.runSequential(
      ProcessType.NOTIFICATION,
      async () => {
        const undo = jsonClone(WD.store.myNotifications);
        try {
          const newNotifications = jsonClone(WD.store.myNotifications).filter((n) => n.notificationId !== id);
          WD.store.myNotifications = newNotifications;
          await WD.store.service.markNotificationAsDeleted(id);
        } catch (e) {
          Logger.logError('NOTIFICATION ACTION', 'FAIL TO SET AS DELETED', e);
          WD.store.myNotifications = undo;
        }
      },
      async () => {
        await this.refresh();
      },
    );
  }
  static async subscribeToTopic(type: NotificationTokenType, topic: NotificationTopic) {
    const token = this.getSavedToken(type);
    await WD.store.service.subscribeToTopic([token], this.getBuildTypeTopicName(topic));
  }
  private static getSavedToken(type: NotificationTokenType) {
    return CustomerActions.getCustomAttribute(`device_token_${type}` as any);
  }
  static async unsubscribeToTopic(type: NotificationTokenType, topic: NotificationTopic) {
    const token = this.getSavedToken(type);
    await WD.store.service.unsubscribeFromTopic([token], this.getBuildTypeTopicName(topic));
  }
  static getBuildTypeTopicName(topic: NotificationTopic) {
    return `${getBuildType()}-${topic}`.toLowerCase();
  }
  static userAllowPromotional(type: WebOrApp) {
    return CustomerActions.getCustomAttributeBoolean(`notif_delivery_${type}` as any);
  }
  static userAllowAlert(type: WebOrApp) {
    return CustomerActions.getCustomAttributeBoolean(`notif_delivery_${type}` as any);
  }
  static setAllowPromotion(type: WebOrApp, boo: boolean) {
    return CustomerActions.setCustomAttributeBoolean(`notif_broadcast_${type}` as any, boo);
  }
  static setAllowAlert(type: WebOrApp, boo: boolean) {
    return CustomerActions.setCustomAttributeBoolean(`notif_broadcast_${type}` as any, boo);
  }
  static getUnreads() {
    return WD.store.myNotifications ? WD.store.myNotifications.filter((n) => !n.read) : [];
  }
}
